<template>
    <!-- Footer Bootstrap Light -->
<footer class="footer  py-3 bg-light m-0 p-0">
  <div class="container ">
    <span class="text-muted">&copy; 2024 Gilles Bertrand Tchako Ngangue. Tous droits réservés. </span>
  </div>
</footer>
    </template>
    
    <script>
    export default {
      name: 'AppFooter',
      
    }
    </script>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
    <style scoped>
    
    </style>
    